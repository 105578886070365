// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meio-ambiente-js": () => import("./../../../src/pages/meio-ambiente.js" /* webpackChunkName: "component---src-pages-meio-ambiente-js" */),
  "component---src-pages-pesquisa-js": () => import("./../../../src/pages/pesquisa.js" /* webpackChunkName: "component---src-pages-pesquisa-js" */),
  "component---src-pages-regioes-js": () => import("./../../../src/pages/regioes.js" /* webpackChunkName: "component---src-pages-regioes-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nosso-time-js": () => import("./../../../src/pages/sobre/nosso-time.js" /* webpackChunkName: "component---src-pages-sobre-nosso-time-js" */),
  "component---src-pages-sobre-quem-somos-js": () => import("./../../../src/pages/sobre/quem-somos.js" /* webpackChunkName: "component---src-pages-sobre-quem-somos-js" */),
  "component---src-pages-sobre-trabalhe-conosco-js": () => import("./../../../src/pages/sobre/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-sobre-trabalhe-conosco-js" */),
  "component---src-pages-solucao-js": () => import("./../../../src/pages/solucao.js" /* webpackChunkName: "component---src-pages-solucao-js" */),
  "component---src-pages-solucoes-setor-js": () => import("./../../../src/pages/solucoes/setor.js" /* webpackChunkName: "component---src-pages-solucoes-setor-js" */),
  "component---src-pages-solucoes-softwares-js": () => import("./../../../src/pages/solucoes/softwares.js" /* webpackChunkName: "component---src-pages-solucoes-softwares-js" */),
  "component---src-pages-solucoes-tecnologia-js": () => import("./../../../src/pages/solucoes/tecnologia.js" /* webpackChunkName: "component---src-pages-solucoes-tecnologia-js" */),
  "component---src-pages-solucoes-varejo-js": () => import("./../../../src/pages/solucoes/varejo.js" /* webpackChunkName: "component---src-pages-solucoes-varejo-js" */),
  "component---src-templates-categories-index-js": () => import("./../../../src/templates/categories/index.js" /* webpackChunkName: "component---src-templates-categories-index-js" */),
  "component---src-templates-products-index-js": () => import("./../../../src/templates/products/index.js" /* webpackChunkName: "component---src-templates-products-index-js" */)
}

